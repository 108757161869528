<template>
  <div class="landing-page-font-family">
    <div class="w-100 video-block">
      <div class="landing-page-video-content w-50 float-start text-start">
        <h1>{{ landingPageContent.video.header[0] }}</h1>
        <h1 class="text-primary">
          {{ landingPageContent.video.header[1] }}
        </h1>
        <h1>{{ landingPageContent.video.header[2] }}</h1>
        <p class="fs-3">
          {{ landingPageContent.video.subHeader }}
        </p>
      </div>
      <div class="d-flex justify-content-between mb-5 text-white video-footer">
        <div>
          <b-img
            :src="KINGPIN_LANDING_PAGE_SVG"
            alt="Kingpin Logo"
          />
        </div>
        <div class="mt-1">
          {{
            landingPageContent.video.footer.copyrights +
              new Date().getFullYear()
          }}
        </div>
      </div>
      <video
        id="landing_page_video"
        autoplay
        loop
        muted
      >
        <source
          src="../../assets/videos/landing-page-video.mp4"
          type="video/mp4"
        >
        <source
          src="../../assets/videos/landing-page-video.webm"
          type="video/webm"
        >
      </video>
    </div>
    <login-form />
  </div>
</template>

<script>
import KINGPIN_LANDING_PAGE_SVG from '@/assets/images/base64-images/kingpin-home-logo'
import landingPageContent from '@/assets/app-content/landing-page.json'
import LoginForm from '../../components/authentication/LoginForm.vue'
// Content from json file

export default {
  name: 'LandingPage',
  components: {
    LoginForm,
  },
  data() {
    return {
      KINGPIN_LANDING_PAGE_SVG,
      landingPageContent,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/@core/scss/vue/pages/landing-page.scss";
</style>
