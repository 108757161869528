<template>
  <div
    class="
      position-relative
      w-50
      bg-light
      float-end
      d-flex
      align-items-center
      justify-content-center
      login-block
    "
  >
    <div class="w-50">
      <div class="text-start text-wrap">
        <h1 class="welcome-header fw-bold lh-lg">
          {{ loginFormContent.welcomeHeader }}
        </h1>
        <h6 class="mb-2">
          {{ loginFormContent.subHeader }}
        </h6>
      </div>
      <validation-observer
        ref="loginForm"
        #default="{ invalid }"
      >
        <!-- form -->
        <b-form
          class="mt-3 text-start"
          @submit.prevent="login"
        >
          <!-- email -->
          <validation-provider
            #default="{ failedRules }"
            name="Email"
            vid="email"
            rules="required|email"
          >
            <k-form-group
              :form-invalid-content="failedRules[0]"
              :state="failedRules.length > 0 ? false : null"
            >
              <template>
                <label for="login-email">{{
                  loginFormContent.fields.email.label
                }}</label>
              </template>

              <k-form-input
                id="login-email"
                v-model="userEmail"
                class="mt-1"
                name="login-email"
                :placeholder="loginFormContent.fields.email.placeholder"
              />
              <small class="text-danger">
                {{
                  failedRules.email
                    ? `Please enter proper ${loginFormContent.fields.email.label}`
                    : ""
                }}
                {{
                  failedRules.required
                    ? `Please enter ${loginFormContent.fields.email.label}`
                    : ""
                }}
              </small>
            </k-form-group>
          </validation-provider>
          <!-- forgot password -->
          <validation-provider
            #default="{ failedRules }"
            name="Password"
            vid="password"
            rules="required"
          >
            <k-form-group
              :form-invalid-content="failedRules[0]"
              :state="failedRules.length > 0 ? false : null"
              class="mt-2"
            >
              <template>
                <label for="login-password">{{
                  loginFormContent.fields.password.label
                }}</label>
              </template>
              <k-form-input
                id="login-password"
                v-model="password"
                class="mt-1"
                type="password"
                name="login-password"
                :placeholder="loginFormContent.fields.password.placeholder"
              />
              <small class="text-danger">
                {{
                  failedRules.required
                    ? `Please enter ${loginFormContent.fields.password.label}`
                    : ""
                }}
              </small>
            </k-form-group>
          </validation-provider>
          <!-- checkbox -->
          <k-form-group class="mt-3">
            <div class="d-flex justify-content-between">
              <label
                class="custom-circle-checkbox-container"
              >{{ loginFormContent.RememberMe }}
                <input
                  v-model="status"
                  type="checkbox"
                  :checked="status"
                >
                <span class="checkmark" />
              </label>
            </div>
          </k-form-group>
          <!-- submit buttons -->
          <k-button
            class="mt-3"
            variant="info"
            type="submit"
            block
            :disabled="invalid || loading"
          >
            {{ loginFormContent.buttons.signIn }}
            <loader-icon
              v-if="loading"
              class="ms-1"
            />
          </k-button>
        </b-form>
      </validation-observer>
    </div>
    <div class="footer-content mb-5 w-50 text-start">
      <span>{{ loginFormContent.footer.text }}</span>
      <b-link
        class="text-decoration-none fw-bolder"
        href="https://kingpin-global.slack.com/messages/product/"
      >&nbsp;{{ loginFormContent.footer.link }}.</b-link>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { KButton, KFormGroup, KFormInput } from '@kingpin-global/kingpin-ui'
import { LOGIN } from '@/store/modules/auth.module'
// Content from json file
import loginFormContent from '@/assets/app-content/login-form.json'
import { required, email } from '@/@core/utils/validations/validations'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import { LoaderIcon } from 'vue-feather-icons'

export default {
  name: 'LoginForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    KButton,
    KFormGroup,
    KFormInput,
    LoaderIcon,
  },
  data() {
    return {
      loginFormContent,
      status: false,
      password: '',
      userEmail: '',
      loading: false,
    }
  },
  computed: {
    required() {
      return required
    },
    email() {
      return email
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.loading = true
          setTimeout(() => {
            this.$store
              .dispatch(LOGIN, {
                email: this.userEmail,
                password: this.password,
              })
              .then(data => {
                if (data) {
                  this.$router.push({ name: 'order-list' })
                  apiToastSuccess(
                    data.message || 'You have successfully logged in!',
                    'CoffeeIcon',
                  )
                }
              })
              .catch(message => {
                if (message) {
                  apiToastWarning(message)
                  this.loading = false
                }
              })
          }, 1000)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/@core/scss/vue/components/login-form-component.scss";
</style>
